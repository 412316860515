//Variables for css styles to be defined here

//Button styles
$button-color: #345561;
$button-background-color: #e4e7eb;
$button-border-color: #c5d2d6;

//Button styles on HOVER
$button-background-color-hover: #c5d2d6;
$button-border-color-hover: #c7cdd5;
