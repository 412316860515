.k-list-container {
  li.k-item {
    font-size: small;
  }
}

.GridCustomStyle {
  .k-grid {
    height: 100%;
    border: 0;
  }

  .GridToolBar {
    display: flex;
    flex-direction: row-reverse;
  }
  .k-header {
    font-size: medium;
    border-left-width: 1px;
    background-color: transparent;
    border: 0;
    border-color: transparent;
    box-shadow: 0 0 transparent;
    border-width: 0 0 0;
  }
  .k-grid .k-grid-container,
  div.k-grid-header,
  div.k-grid-footer {
    border: 1px solid rgba(33, 37, 41, 0.125);
  }

  .k-grid-header-wrap {
    table {
      min-width: 800px;
    }
  }

  .k-grid-content {
    overflow: scroll !important;
    cursor: pointer;
    min-height: 50vh;
    max-height: 75vh;
    table {
      user-select: text;
      min-width: 800px;
    }
  }

  input {
    border: 2px solid #dadada;
    border-radius: 7px;
    padding: 8px;
  }
  .k-checkbox{
    border-radius: 5px !important;
  }
  input:focus {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }

  .k-grid-norecords {
    width: inherit !important;
    background-color: #ededed;
  }

  .grid-no-records {
    padding-top: 10%;
    height: 100%;
    width: 33%;
    display: flex;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .k-grid-norecords :hover {
    // background-color: #ededed00!important;
    cursor: default;

    .list-group {
      background-color: revert;
    }
  }

  .noRecords :hover {
    background-color: transparent;
  }

  .k-grid-container {
    tr.k-state-selected > td {
      background-color: #f4f5f5 !important;
      color: #282b2d !important;
    }

    .k-state-selected {
      background-color: #f4f5f5 !important;
      color: #282b2d !important;
    }

    div.jsx-parser {
      display: contents;
      td {
        border-width: 0 0 0 1px;
        border-color: rgba(33, 37, 41, 0.125);
      }
    }
  }

  .k-grid-edit-cell .k-textbox {
    font-size: 14px;
  }

  .k-filtercell > .k-filtercell-wrapper > input::placeholder {
    font-size: small;
  }

  .k-filtercell > .k-filtercell-wrapper > input {
    font-size: small;
    margin-bottom: 0px;
    height: 25px;
  }

  .k-filtercell {
    .k-datepicker {
      height: 35px;
      .k-dateinput-wrap {
        input {
          font-size: small !important;
          height: 34px;
        }
      }
    }
  }

  .k-grid-toolbar {
    border: none !important;
    .k-dropdown-button {
      float: right;
      margin-right: 5px;
    }
  }
  .k-pager-numbers li a.k-state-selected {
    color: #fff;
    background-color: #275362;
    border-color: #275362;
  }
  .k-pager-numbers li a {
    color: #275362;
  }
  .page-item.active .page-link {
    background-color: #275362;
    color: #fff;
    border-color: #275362;
  }
  .k-pager-wrap .k-link,
  .k-pager-wrap .k-pager-nav {
    color: black;
  }

  .k-button {
    font-size: small;
  }

  .k-header {
    font-size: medium;
    border-left-width: 1px;
  }

  .k-grid-header-wrap {
    border-left-width: 1px;
  }

  .k-grid-pager {
    border-width: 1px;
  }

  .k-grid-table {
    td {
      font-size: 14px;
    }
  }

  abbr {
    border-bottom: unset;
  }

  .gridActions {
    // display: flex;
    justify-content: space-evenly;
    cursor: default;
    // flex-wrap: wrap;

    abbr {
      padding: 3px;
      text-decoration: none;
    }

    .manage-btn {
      //manage icons style and style
      border-radius: 50%;
      font-family: Roboto;
      cursor: pointer;
      // width: auto;
      height: 40px;
      width: 40px !important;
      background-color: #f6f8fa !important;
      border: solid 1px#8f9bb3 !important;
      color: #8f9bb3 !important ;
    }
    
    .manage-btn:hover {
      background-color: #8f9bb3 !important ;
      color: #f6f8fa !important;
      border: solid 1px#f6f8fa !important;
    }

    // .manageIcons {
    //   font-size: 1.1em;
    //   color: #8f9bb3 !important; 
    //   display: block;
    // }
  }

  // .gridPdfPage {
  //   display: none;
  // }

  .fa-comments-o.manageIcons {
    font-size: 1.4em;
  }
}

.k-pdf-export {
  p {
    font-size: 14px;
  }

  .k-master-row {
    td {
      font-size: 12px;
    }
  }

  .k-header {
    .k-link {
      font-size: medium;
      font-weight: bold;
    }
  }
}
.k-filterable {
   font-size: 12px !important;
}
.k-header-customtitle{
  font-size: 20px !important;
  // text-align: center !important;
}