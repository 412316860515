$primary-color: #275362;

.Navigation {
  position: relative;
  padding: 0 15px;
  height: 100%;
  width: 100%;
  overflow: scroll;

  .AppBuilderPage {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
  }
  .customActions {
    // margin: 5px 0px 10px 35px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  .dash-manager-buttons {
    right: 0px;
    width: auto;
    float: left;
    position: relative;
  }

  .dash-manager-buttons .btn-primary {
    color: #345561;
    background-color: #c5d2d6;
    border-radius: 42px;
    font-size: 13px;
    box-shadow: 0px 0px 5px 0px grey;
    height: auto;
    padding: 5px 10px;
    border-radius: 100%;
  }

  .dash-manager-buttons .btn {
    // margin-right: 7px;
    margin: 0px 4px;
    border-color: #eaeaea;
  }

  .dashboard .btn-primary {
    color: #fff;
    background-color: #265160;
    border-color: #265160;
  }
  .display-flex {
    display: flex;
  }
  .task-header-pos-abs {
    position: absolute;
    top: 0;
    right: 0;
  }
  .width-100 {
    width: 100%;
  }
  .task-header {
    padding: 5px 0 5px 0px;
    display: flex;
    align-items: center;
    .go-back {
      transform: rotate(180deg) !important;
      cursor: pointer;
    }

    &_info {
      flex-direction: column;
      gap: 10px 0;
    }

    .task-header_details,
    .task-header_details > div,
    &_info {
      display: flex;
    }

    .task-header_name {
      margin: 3px 2px 3px 9px;
      font-size: 1.25rem;
      color: #1f1f67;
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
    }

    .task-header_details {
      div {
        margin-left: 10px;
        align-items: center;
        p {
          margin: 0;
          margin-left: 5px;
        }
        div:not(:first-child),
        p:not(:first-child) {
          font-size: 12px !important;
          color: #696982;
        }
      }
      .owner-assignee,
      div > div:first-child,
      div > p:first-child {
        font-size: 12px !important;
        color: #000 !important;
      }
      .owner-assignee {
        .owner-assignee-dp {
          padding-left: 0.5rem;
          font-size: 20px;
        }
      }
      .task-status {
        padding: 5px;
        border-radius: 10px;
        // background-color: rgba(0, 128, 0, 0.83);
        margin: 0 6px;
      }

      //PROGRESS

      .task-header_progress {
        flex: 1;
        display: flex;
        justify-content: end;
        div {
          &:first-child {
            justify-content: space-between;
            padding-bottom: 6px;
            width: 15rem;
          }
          & {
            display: flex;
            width: 100%;
          }
        }
        &--data {
          display: flex;
          flex-direction: column;
          width: 15rem;
          margin: 0px 20px 0rem 5rem;

          p {
            strong {
              padding-right: 5px;
            }
          }
        }
      }
    }

    &_taskname {
      border-radius: 50px;
      background: #bddce6;
      margin-left: 7.5px;
      font-size: 1.25rem;
      min-height: 50px;
      display: flex;
      min-width: 50px;
      justify-content: center;
      align-items: center;
    }
  }

  // .toolBarButton {
  //   margin: 0 10px;
  //   display: inline-block;
  //   font-weight: 400;
  //   color: #fff;
  //   text-align: center;
  //   vertical-align: middle;
  //   -webkit-user-select: none;
  //   -moz-user-select: none;
  //   -ms-user-select: none;
  //   user-select: none;
  //   background-color: transparent;
  //   border: 1px solid transparent;
  //   padding: .375rem .75rem;
  //   font-size: 1rem;
  //   line-height: 1.5;
  //   border-radius: 50%;
  //   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  //   float: right;
  // }
  .breadcrumbs {
    padding-top: 5px;
    padding-bottom: 0.8rem;
    display: contents;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 30px;
    border-radius: 1rem;
  }

  .breadcrumbs-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    color: $primary-color;
    a {
      color: $primary-color;
      padding-left: 5px;
      font-size: 24px;
    }
    a:focus,
    a:hover {
      text-decoration: none;
    }
    .seperator {
      margin-right: 0.5rem;
      color: #bababb;
      font-size: 24px;
    }
  }
  .searchInput {
    border: 0;
    background: transparent;
    letter-spacing: 1px;
    font-weight: 600;
    color: #1a1a1a;
    height: 30px;
    padding: 0px 0px 5px 10px;
    font-size: 1.75rem;
    font-weight: 300;
    width: 100%;
  }

  abbr {
    text-decoration: none;
  }
  .contentDiv {
    position: relative;
  }
  .page-active,
  .contentDiv {
    height: 100%;
  }
  .activeBreadcrumb a {
    color: #5cd4e3 !important;
  }
  .activeBreadcrumb a:hover {
    color: #30c2d2 !important;
    text-decoration: underline !important;
  }
  .tab-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 0.5rem 2rem;
    cursor: pointer;
    margin-right: 0;
    background-color: transparent;
    border: none;
    color: #444;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    padding-bottom: 1.125rem;
    // width: 20%;
  }
  .Navigation .tab-link:focus {
    outline: transparent;
  }
  .tab-link:hover,
  .tab-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
    outline: 0;
  }
  .tab-link-active {
    border: none;
    -webkit-box-shadow: inset 0 -2px 0 #30c2d2;
    box-shadow: inset 0 -2px 0 #0a6ed1;
    color: #0a6ed1;
  }
  .tab-content .contentDiv {
    flex: 1 1 auto;
    min-height: 1px;
    // padding: 1.25rem;
    // padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  }

  .searchBar {
    display: flex;
  }

  .appButtons {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .moduleBtn {
      align-items: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .block {
        background-color: $primary-color;
        border-radius: 10px;
        color: white;
        font-size: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 125px;
        width: 125px;
      }

      .titles {
        font-size: 26px;
        color: $primary-color;
        padding: 25px;
      }
    }
  }
}

.searchInput {
  border: 0;
  background: transparent;
  letter-spacing: 1px;
  color: #1a1a1a;
  height: 30px;
  // padding: 0px 1rem 0 2.5rem;
  font-size: 1.75rem;
  font-weight: 300;
  display: inline-block;
  width: 100%;
}
.searchInput:focus {
  outline-color: transparent;
}

.k-popup {
  font-size: small;
  margin: unset;
  padding: unset;
}

.k-item:focus,
.k-list .k-item.k-state-focused {
  box-shadow: unset !important;
}

.k-list .k-item {
  background-color: #2a4f5d;
  color: white;
}

.breadcrumbParent {
  border-bottom: 1px solid $primary-color;
  margin-bottom: 0.8rem;
}

.breadcrumbHearder {
  margin-bottom: 0px;
}

.loaderAnimation {
  display: flex;
  height: 100%;
}

.loaderAnimation > div {
  margin: auto;
}
.searchPageDiv {
  width: 100%;
}
.searchPageDiv .seperator {
  border-bottom: 1px solid $primary-color;
  width: 80%;
  margin-top: 10px;
}
.searchText {
  padding: 1.5em;
}
.searchPageDiv .icon {
  box-sizing: border-box;
  color: #a5b4c4;
  display: block;
  fill: #a5b4c4;
  font-family: MaisonNeue, -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "Lucida Grande", sans-serif;
  font-size: 24px;
  height: 26px;
  position: relative;
  text-size-adjust: 100%;
}

.page-dimmed {
  filter: grayscale;
  opacity: 0.7;
}
.page-inactive {
  display: none;
}
.page-active {
  display: block;
}
.rygBadge {
  margin-left: auto;
  width: 80px;
  border: 3px solid lightgrey;
}

// Hide extra upload button in attachment section [BUG] #26242 by Mukul
div[class="k-actions k-hstack k-justify-content-end"]
  > button[class="k-button k-primary k-upload-selected"] {
  display: none;
}

// Extra Space above the custombar
div#customActions > div[class="display-flex task-header-pos-abs"] {
  margin-top: 10px;
}
#customActions>button[title="View"]{
  display: none;
}