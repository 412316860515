.docViewerComponent {
  display: flex;
  height: inherit;

  &_docListDiv{
    .accordion{
      .card{
        border: 1px solid rgba(0, 0, 0, 0.125) !important;

        .docListBody{
          p{
            a{
              color: #007bff;
              text-decoration: none;
              background-color: transparent;

              &:hover{
                text-decoration: underline;
                color: #0056b3;
              }
            }
          }
        }
      }
    }
  }

  .borderActive {
    border: 1px solid #275362;
    box-shadow: 0px 0px 3px 1px #275362;
    transform: scale(1.01);
    transition-property: transform;
    transition-duration: 0.5s;
  }

  .docListDiv {
    height: 100%;
  }

  .docList {
    cursor: pointer;
  }
  .fileName {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
  }

  .closeButton {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    height: 80px;
    overflow: hidden;
    position: absolute;
    right: -40px;
    top: -40px;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
    width: 80px;
  }

  .documentsWrap {
    margin-top: 10px;
    border: solid lightgray;
    border-radius: 10px;
  }

  .docListBody {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;

    p {
      margin: 2px 1px 2px 1px;
      line-height: normal;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .docIcon {
    font-size: 18px;
    padding: 4px 4px;
  }

  .iframeDoc {
    height: 100% !important;
    min-height: 650px !important;
    width: 100% !important;
    height: -moz-available;
    height: fill-available;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .docListBody.border {
    cursor: pointer;
    border: unset !important;
  }

  .accordion {
    height: inherit;
    overflow: auto;
  }

  .docViewerWindow {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rotate90 {
    transform: rotate(90deg);
    transition: -webkit-transform 0.2s ease-in;
  }

  .image-download-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 35px;
    justify-content: space-around;
    text-shadow: 0 0 2px #000;
    color: white;
    border-radius: 0.25rem;
    background-color: black;
    padding: 20px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    opacity: 0.7;
  }

  .image-download-button:hover {
    opacity: 1;
  }

  .card {
    .card-header {
      text-align: center;
      background-color: #275362;
      padding: 0px;

      button {
        color: white;
        font-weight: bolder;
        font-size: 16px;
        padding: 0.3rem 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: start;
        width: 100%;
        margin: 0px;
      }
      .btn-primary:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .card-body {
    padding: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    color: #343a40;

    .card {
      border: unset !important;
    }
  }
  .docItems.card {
    max-height: 3rem;
    margin: 0.4vh;
    border-radius: 15px;
  }

  .singleFile {
    padding: 0.5em 1em 0.5em 0.2em;
    display: flex;
    flex-direction: row;
    align-items: center;

    .fileIcon {
      padding-right: 5px;
    }
  }
  .uploadFile {
    padding: 5px 90px;
  }
  .popupWindow {
    .k-action-buttons {
      display: none;
    }
    .k-upload .k-dropzone .k-dropzone-hint {
      color: black;
    }
    .uploadButton {
      border: 2px solid gray;
      color: gray;
      background-color: white;
      padding: 5px 10px;
      border-radius: 8px;
      font-size: 16px;
      margin: 5px;
      float: right;
    }
    .invalidButton {
      cursor: not-allowed;
    }
  }

  .row {
    width: inherit;
    .col-md-12 {
      display: flex;
      .btn-dark {
        background-color: black;
        opacity: 0.7;
      }
      .btn-dark:hover {
        opacity: 1;
      }
    }
  }

  .pdf-frame {
    width: inherit;
  }

  .show-text {
    padding-top: 10px;
    white-space: break-spaces;
  }
}
