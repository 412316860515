
/* figure, */
figure.oxzion-widget,
div.oxzion-widget
{
    text-align:center;
    outline:solid 1px #ccc;
    background:rgba(0,0,0,0.05);
    margin:10px 20px;
    display:inline-block;
    position:relative;
}

/* figure > figcaption, */
figure.oxzion-widget > figcaption
{
    text-align:center;
    /* display:block; */
    display:none;
}

figure.oxzion-widget > div.oxzion-widget-roll-up-button, 
div.oxzion-widget > div.oxzion-widget-roll-up-button {
    z-index:2;
    position:absolute;
    top:0px;
    right:0px;
    font-size:30px;
    margin:10px;
    cursor:pointer;
}

.oxzion-widget-content {
    position: relative;
}

div > div.oxzion-widget-drilldown-icon{
    top: 20px;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    color: #265160;
    right: 3px; 
}

div > div.oxzion-widget-drilldown-table-icon{
    color: #265160;
    z-index: 10;
    position: relative;
    float: right;
    top: 20px;
    margin-right: 10px;
    margin-left: 12px;
}

.oxzion-widget-drilldown-excel-icon{
    top: 20px;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    color: #265160;
    right: 2px;
}

.oxzion-widget-aggregate-drilldown{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.red {
    color: #EE4424;
    font-weight: bold;
}

.green {
    color:#A3C53A;
    font-weight: bold;
}

.yellow {
    color: #F3BA1D;
    font-weight: bold;
}


/* This is to make the header same as the headers in the app builder appa list view.*/
.oxzion-widget [role="columnheader"] {
    border: 0;
    border-width: 0;
    border-color: transparent;
}