    .k_expense-item {
        display:flex;
        flex-direction:row;
        flex-flow: row; 
        /*padding: 0.5rem;*/
        margin: 5px 0; 
        flex-wrap: initial;
      
    
    }
    
    .k_nav-button {
        display:flex;
        flex-direction:row;
        flex-flow: row;
        align-items: flex-start;
        padding: 0.5rem;
        margin: 0.5rem;
        flex-wrap: initial;
    }
    
    .k_cards {
        flex: 0 0 12.5%;padding: 1px;
        margin: 20px;
        box-shadow :2px 3px 4px 4px lightgrey;
        background-color: white;
        max-width: 120px;
        height: 145px;
    }
    
    .k_taskcards {
        flex: 0 0 25%;padding: 1px;
        margin: 0px;
        background-color: white;
        // max-width: 300px;
        height: auto;
        border: none;
        border: 0;
    }
    
    // .k_taskcards:hover .img {
    //     border-color: rgb(99, 204, 131);
    // }
    
    .k_img{
        border: solid 1px #3a3a3a;
        border-radius: 50%;
        width: 15px !important;
        height: 15px !important;
    }
    .k_status{
        height: 10px;
        width: 10px;
        margin-right: 5px;
        // background-color: #bbb;
        border: 1px solid black;
        border-radius: 50%;
        display: inline-block;
    }
    
    .k_taskcardDisplay{
        margin-top: 0.5rem;
        padding: 0;
        display: flex;
        flex-direction: row;
    }
    
    .k_taskcardDisplay1{
        height: auto;
        width: auto;
    
        display: flex;
        flex-direction: row;
    }
    
    .k_card{
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        margin-right: 3%;
        background-color: #fff;
        background-clip: border-box;
        /* border: 1px solid rgba(0, 0, 0, 0.125); */
        border-radius: 0;
    }
    
    .k_lanes_list{
        box-shadow: 0px 2px 9px 4px #302b2b08;
        width: fit-content;
    }
    
    .k_listDiv{
        height: auto;
        max-height:70vh;
        overflow-y: auto;
        word-break: break-word;
        scrollbar-width: thin;
    }
    .k_listDiv:hover{
        overflow-y:auto;
    }
    
    /* .listDiv:hover{overflow-y: scroll;}  */
    .k_listDiv:active::-webkit-scrollbar-thumb {
        visibility: visible;
    }
    
    .k_itemCard{
        text-align: center;
    }
    /*
    .k_calendar{
    top: 2rem;
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    
    align-items: flex-start;
    
    }
    .k_fab{
    display: flex;
    margin-left: 15px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 2px;
    }
    .k_select{
    top: 2rem;
    position: initial;
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-top: 0%;
    
    align-items: flex-start;
    
    }
    .k_button{
    box-shadow :2px 3px 4px 4px lightgrey;
    background-color: white;
    border:ivory
    
    }
    
    .k_Dropdown_menu__3FlHi{
    right: inherit;
    } */
    
    .k_list-group-item{
        padding: 0;
    }
    
    .k_p{
        margin: 0;
    }
    
    
    .k_itemGroupName{
        font-size: small;
        margin: 5%;
        color: black;
    }
    .k_menu-icon{
        color: black;
        padding-right: 5px;
    }
    .k_toggle{
        background-color: white;
        border: none;
        padding-top: 7px;
        color:black;
        font-size: small;
    }
    .k_labels{
        background-color: #fff;
        border: none;
        color: black;
        display: block;
        margin-left: 2rem;
        height: 35px;
        padding-top: 7px;
        margin-right: 0px;
        font-size: small;
    }
    .k_taskcard-label{
       color: #969696;
       font-size: smaller;
       text-align: left;
       margin-left: 30px;
       margin-bottom: 0;
    }
    .k_taskcard-sublabel{
        color:#6e6e6e;
        font-weight: 600;
    }
    .k_taskcard-p{
        padding: 0px;
        font-size: smaller;
        margin: 0 0 0 10px;
        padding-top: 5px;
        font-weight: bold;
        color:#242424;
        margin-bottom: 0.2rem;
    }
    .k_label-calendar{
        background-color: #fff;
        border: none;
        color: black;
        display: block;
        margin-left: 2rem;
        height: 35px;
        padding-top: 7px;
        margin-right: 2rem;
        font-size: medium;
        margin-top: 2.5rem;
        margin:45px
    }
    .k_input{
        width: 150px;
        margin-left: 1rem;
    }
    