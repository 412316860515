.tabs{
    width: 100%;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.links {
    padding: .75rem 1.25rem;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
    background: #e4e7eb;
  }
  .visibleTabStyle {
    display: block;
  }