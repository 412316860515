body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-y: visible !important;
}

.swal2-shown {
  overflow: visible !important;
}

body.swal2-shown .swal2-container,
.swal2-container {
  z-index: 10020;
}