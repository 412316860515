.widget-manager .deactivateWidget .react-switch-bg{
    background: rgb(39, 83, 98)  !important;
  }
  .widget-manager .react-switch-handle{
    background: #fff !important;
  
  }
  .page-body .widget-manager{
    margin-left: 0.5em;
    margin-right: 0.5em;
  }