  .m_App {
    text-align: center;
  }

  .m_App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .m_App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .m_App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .m_App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .m_search {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }

  .m_search input {
    padding: 0.5rem;
    font-size: 1.5rem;
    width: 100%;
  }

  .m_item {
    background: var(--clr-white);
    display: flex;
    justify-content: space-between;
    max-width: var(--fixed-width);
    margin: 2rem auto;
    align-items: center;
    border-radius: var(--radius);
  }

  .item button,
  .m_item a {
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    letter-spacing: var(--spacing);
    cursor: pointer;
  }

  .m_item {
    padding: 1rem 2rem;
  }

  .m_item h4 {
    margin-bottom: 0;
  }

  .m_item p {
    margin-bottom: 0;
  }

  .m_btn {
    display: inline-block;
    background: var(--clr-primary-5);
    color: var(--clr-white);
    padding: 0.25rem 0.75rem;
    border-radius: var(--radius);
    border-color: transparent;
    text-transform: capitalize;
    font-size: 1rem;
    letter-spacing: var(--spacing);
    margin-top: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
  }

  .m_btn:hover {
    background: var(--clr-primary-1);
    color: var(--clr-primary-5);
  }

  .m_infowindow {
    width: 200px;
    background-color: #61dafb;
  }
