    .k_cardStyle{
        width: 150px;
    }
    
    .k_cardBody .card{
        border: 0;
        border-radius: 0;
    }
    
    
    .k_cardCount{
        font-size: 42px;
        font-weight: 600;
        color: #6c6c6c;
        text-align : center;
    }
    
    
    .k_cardStatusName{
        text-align : center;
        font-size : smaller;
        color : #b7b7b7;
    }
    
    .k_cardIcon{
        color:black;
        float : left;
        margin: 0;
    }