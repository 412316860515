.map-container {
    height: 400px;
    }
     
    .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #ffffff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 30px;
    left: -10px;
    margin: 12px;
    border-radius: 4px;
    }
