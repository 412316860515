.Navigation {
  .commentsPage {
    $gray-light: #6c757d10;
    $gray-hover: #6c757d20;
    $gray: #6c757d;
    $pblue-light: #4c75f210;
    $pblue: #4c75f2;
    $pblue-hover: #4c75f230;
    --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    --msger-bg: #fff;
    --border: 2px solid #ddd;
    --left-msg-bg: #ececec;
    --right-msg-bg: #579ffb;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    height: 98%;
    // border: var(--border);
    border-radius: 5px;
    background: var(--msger-bg);
    // box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
    .msger-inputarea {
      display: flex;
      padding: 0px;
      border-top: 0px;
      background: transparent;
    }
    #chat-container {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
      min-height: 12rem;
      height: calc(100vh - 290px);
    }

    #emoji-list {
      z-index: 50;
      margin-bottom: -400px;
      right: 15%;
      position: absolute;
      bottom: 27rem;
      right: 8rem;
    }

    .commentsSaveButton {
      margin-left: 10px;
      background: rgb(0, 196, 65);
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
    }

    .updates {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 100%;
      margin-bottom: 1rem;

      .update {
        background: $gray-light;
        margin-bottom: 0.5rem;
        padding: 0.75rem;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        word-break: break-word;

        &:hover {
          background: $gray-hover;
        }

        .k-button k-primary {
          width: 60px;
          height: 40px;
          margin-left: 30px;
        }

        h5,
        h4 {
          margin: 0;
        }

        h5 {
          color: $gray;
          font-weight: 600;
        }

        h4 {
          color: $gray;
          font-weight: 600;
        }
      }

      .button {
        background: $pblue-light;
        height: 3rem;
        display: flex;
        flex-direction: row;
        color: $pblue;
        width: 13rem;
        & > * {
          align-self: center;
        }

        &:hover {
          background: $pblue-hover;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
        flex-direction: row;
        display: flex;
        & > * {
          align-self: center;
        }
      }
    }

    .commentBox {
      flex: 1;
      background: #ddd;
    }
    .msger-inputarea * {
      border: none;
      border-radius: 3px;
      font-size: 1em;
      background: transparent;
    }
    .sendButton {
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
    }
    .msg {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
    }
    .msg-bubble {
      max-width: 100%;
      padding: 5px;
      // border-radius: 15px;
      // background: var(--left-msg-bg);
      word-break: break-word;

      background: transparent;
      color: #275362;
      width: 95%;
      border-radius: 5px;

      display: flex;
      // align-items: center;
    }
    .msg-text {
      white-space: pre-wrap;
      display: grid;
    }
    .msg-info {
      // display: inline-flex;
      // justify-content: space-between;
      // align-items: center;
      // margin-bottom: 10px;

      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

    }

    .msg-info-extra {

    }

    // .right-msg .msg-bubble {
    //   background: transparent;
    //   color: #275362;
    //   width: 50%;
    //   // border: 1px solid #275362;
    //   border-radius: 5px;
    // }
    // .left-msg .msg-bubble {
    //   background: transparent;
    //   color: #275362;
    //   width: 50%;
    //   border-radius: 5px;
    //   // display: table-column-group;
    //   // border-bottom: 1px solid #275362;
    //   // border-radius: 0px;
    // }
    .msg-info-time {
      font-size: 0.85em;
    }
    .msg-info-name {
      // margin-right: 10px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .right-msg {
      // flex-direction: row-reverse;
    }
    .right-msg .msg-img {
      // margin: 5px;
      float: left;
    }
    .msg-img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      background: #ddd;
      background-repeat: no-repeat !important;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      float: left;
      padding: 15px;
    }
    thead {
      display: none;
    }

    .k-grid-table {
      border-spacing: 1em;

      td:first-child {
        background-color: #ededed;
        border-radius: 15px;
      }

      .k-grid td {
        border-width: 0px;
      }
    }
    .k-grid-header,
    .k-grid-table {
      .k-grid .k-alt {
        background-color: transparent !important;
      }

      th {
        background-color: #f6f8fa;
        color: #8f9bb3;
      }

      td {
        border-bottom: 1px solid #ebeff2;
      }

      .k-grid th,
      .k-grid td {
        padding: 10px !important;
      }
    }

    .mentions {
      width: 100%;

      textarea,
      .mentions__highlighter {
        letter-spacing: 2px;
      }
    }

    // .mentions--multiLine {
    //   overflow-y: scroll !important;
    // }
    .mentions__suggestions {
      z-index: 1000;
    }
    .mentions__suggestions__list {
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 10pt;
    }

    .mentions__suggestions__item {
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .comment-date {
      padding-bottom: 5px;
      font-size: 13px;
    }

    html,
    body {
      height: 100%;
    }

    html {
      font-family: Roboto, sans-serif;
      background: linear-gradient(to right, #57c1eb 0%, #246fa8 100%);
      font-size: 10px;
    }

    body {
      display: grid;
      place-items: center;
    }
    #container {
      min-width: 800px;
      max-width: 1000px;
      max-height: 500px;
      height: 95vh;
      background: #fff;
      border-radius: 15px;
    }
    #chat-message-list {
      .panel {
        border-color: #ddd;
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      }
      .panel.arrow.left:before {
        left: 0px;
        top: 30px;
        border-right-color: inherit;
        border-width: 16px;
      }
      .panel-body {
        padding: 15px;
      }
      .btn-sm {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
      }
      figure.thumbnail {
        display: block;
        padding: 4px;
        margin-bottom: 20px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        text-align: center;
        border-radius: 4px;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      .thumbnail img {
        max-width: 100%;
        max-height: 60px;
      }
      .panel:before {
        left: 0px;
        top: 30px;
        border-right-color: inherit;
        border-width: 16px;
      }
      .btn-default {
        color: #333;
        background-color: #fff;
        border-color: #ccc;
      }
    }
  }
}
