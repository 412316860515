.datasource-modal .modal-body{
    margin-left:15px;
}

.page-content .query{
    margin-left: 0.5em;
    margin-right: 0.5em;
    height: auto;
}

.query-form{
    width: 100%;
    float: left;
    padding: 2em;
    padding-top: 0px;
    background:#a9c4c4;
    border-radius: 19px;
    color:#275362;
    margin-bottom: 1em;
    }
.query-result-div{
    width: 100%;
    float: left;
    margin-bottom: 1em;
}
.query-form label{
color:#275362;
    
}
.errorMsg {
    color: #cc0000 !important;
}
.query-form .btn{
    margin-right: 5px;
}

.disabled-btn{
    pointer-events: none;
    opacity: 0.5;
}

.query-result-tab{
    height: 60vh;
    display: table-cell;
    text-align: center;
    width: 100vw;
    // padding-left: 2em;
    padding-right: 0.1em;
    background: #8cb5b536;
}

.query-result-div .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    outline: 0!important;
}

.querylist-div{
    background: #8cb5b536;
    padding-bottom: 0;
    padding-top: 2px;
    height: 62vh;
}
.querylist-div .GridCustomStyle{
    height: 58vh !important;
}
.query .btn-primary{
    color: #fff;
    background-color: #265160;
    border-color: #265160;
  }
  
.query .btn-primary:hover{
    background-image:none !important;
    background-color:#265160c4 !important;
  }
  
.query .deactivateDash .react-switch-bg{
    background: rgb(39, 83, 98)  !important;
  }
.query .react-switch-handle{
    background: #fff !important;
  
  }

  .disappear{
      display: none;
  }

  #add-query-btn{
    margin-left: 1em;
    margin-bottom: 10px;
  }
  .query .modal-content .btn-primary{
    color: #fff !important;
    background-color: #275362 !important;
    border-color: #275362 !important;
  }

  .query-form-close-btn{
    float: right;
    position: relative;
    top: 5px;
  }

  
  .query-result-div .nav-tabs .nav-link.active{
    background: #265160 !important;
    color:white !important;
  }
  .query-result-div .nav-tabs .nav-link{
    border: 1px solid #265160 !important;
  }

  .query-result-div .nav-tabs{
      border-bottom: 1px solid #275362 !important;
  }

  .query-result-details{
    padding: 0.5em;
    background:#a2b7ca;
    color:black;
    font-weight: bolder;
  }

  .query-result-details span
  {
    color: #0c3d52;
  }

  .query-result-text-tab{
    padding-top: 2em;
  }

  .query-result-text-tab span{
    float: left;
  }