#rcorners{ 
    border-radius: 10px; 
    font-size:14px; 
 } 
 #rcorners-circle {
     border-radius: 100%;
     border-collapse: separate; 
     display: inline-block;
     width: 120px;
     height: 120px;
     line-height: 120px;
 } 
 #rcorners-wi-border {
     border-radius: 25%;
    font-size: 14px; 
 } 
 #rcorners-100 {
     border-radius: 100%;
    font-size: 14px; 
 }
 .table-rounded {
     align-self: center;
     font-family:Lato, sans-serif;
     font-size: 16px;
     text-align: center;
     border-spacing: 0; 
     border: solid #ccc 1px;
     border-radius: 10px;
     box-shadow: 0 2px 2px #ccc;
 }
 
 .table-rounded th:first-child {
     border-radius: 10px 0 0 0;
 }
 
 .table-rounded th:last-child {
     border-radius: 0 10px 0 0;
 }
 
 .table-rounded th:only-child{
     border-radius: 6px 10px 0 0;
 }
 
 .table-rounded tbody tr:last-child td:first-child {
     border-radius: 0 0 0 10px;
 }
 .table-rounded tbody tr:last-child td:last-child {
     border-radius: 0 0 10px 0;
 }
 div.vastyle {
     font-family:Lato, sans-serif;
 }
 tr.total {
     font-family:Lato, sans-serif;
     background: lightgrey;
     color: white;
     font-family:Lato, sans-serif;
     font-size: 16px;
     text-align: center;
 }
 h1 {
     font-family: Lato, sans-serif;
     
 }
 h2 {
     font-family: Lato, sans-serif;
 
 }
 h3 {
     font-family: Lato, sans-serif;
 
 }
 
 .va-table {
     align-self: center;
     font-family:Lato, sans-serif;
     font-size: 14px;
     text-align: center;
 }
 
 .va-table th {
     background: #275362;
     color: white;
     font-family:Lato, sans-serif;
     font-size: 16px;
     text-align: center;
 }
 .va-table-right-align-col td + td {
 text-align: right;
 }
 
 .va-table-striped-col td:nth-child(2n) {
     background: lightgrey;
 } 
 .va-table-striped-row tr:nth-child(2n) {
     background: lightgrey;
 
 }
 
 .oxs-table {
     align-self: center;
     font-family:Lato, sans-serif;
     font-size: 14px;
     text-align: center;
 }
 .oxs-table th {
     background: #275362;
     color: white;
     font-family:Lato, sans-serif;
     font-size: 16px;
     text-align: center;
 }
 .oxs-table-striped-col td:nth-child(2n) {
     background: lightgrey;
 }
 .oxs-table-striped-row tr:nth-child(2n) {
     background: lightgrey;
 
 }
 .ox-table {
     align-self: center;
     font-family:Lato, sans-serif;
     font-size: 14px;
     text-align: center;
 }
 .ox-table th {
     background: #275362;
     color: white;
     font-family:Lato, sans-serif;
     font-size: 16px;
     text-align: center;
 }
 .ox-table-right-align-col td + td {
 text-align: right;
 }
 .ox-table-striped-col td:nth-child(2n) {
     background: lightgrey;
 } 
 .ox-table-striped-row tr:nth-child(2n) {
     background: lightgrey;
 
 }
 .mlet-hide-header th {
 display: none;
 }
 
 /* VA Marketing Team table */
 .vam-table {
     align-self: center;
     font-family:Lato, sans-serif;
     font-size: 14px;
     text-align: center;
 }
 .vam-table th {
     background: #275362;
     color: white;
     font-family:Lato, sans-serif;
     font-size: 16px;
     text-align: center;
 }
 .vam-table-striped-col td:nth-child(2n) {
     background: lightgrey;
 } 
 .vam-table-striped-row tr:nth-child(2n) {
     background: lightgrey;
 
 }
 /* VA Marketing Team table 2*/
 
 .vam2-table {
     font-family: Lato, sans-serif;
     font-size: 15px;
     text-align: left;
     font-weight: bold;
 }
 
 .vam2-table th {
     background-color: #275362;
     color: white;
     padding: 5px;
 }
 .vam2-table td {
     padding: 5px;
 }
 .vam2-table td:nth-child(odd)  {
     background-color: #018181;
     color: white;
 }
 
 .vam2-table tr:last-child td:first-child,.vam2-table tr:last-child td:last-child {
     color: white;
     background-color: #92D050;
 }
 
 /* VA Marketing Team table 2 END*/
 
 
 @keyframes blinker {  
   0% { visibility: visible; }
   50% { visibility: hidden; }
   100% { visibility: visible; }
 }
 
 .va-cursor {
   animation: blinker steps(1) 500ms infinite alternate;
 }
 
 
 .table-mlet td + td {
     text-align: right;
 }
 
 .table-mlet {
      align-self: center; 
 } 
 .va-mlet td:nth-child(2n) {
     background: lightgrey;
 } 
 .table-mlet th {
     visible: hidden;
     text-align: center;
     background: #275362;
     font-size: 16px;
     color: white;
 }
 .table-local td {
     vertical-align: top;
 }
 .table-local th {
     text-align: center;
     background: #275362;
     font-size: 16px;
     color: white;
 }
 .table-no-border {
   border:0px;
   border-color: white;
 }
 .table-no-header th {
 display: none;
 }
 
 
 /* Used in Products Goals */
 
 .projectsummarytable {
 background: #eee;
     border: 5px solid white;
 }
 .projectsummarytable td{
 border: 5px solid white;
     padding: 2px;
 }
 
 #rends { border-radius: 0 16px 16px 0; } 
 #rstarts { border-radius: 16px 0 0 16px; }
 
 #logo
     {
         display: inline-block;
         position: relative;
     }
 
     #logo .speedometer
     {
         width: 120px;
         height: 120px;
         border-radius: 100%;
         border: 20px solid #000;
         border-color: #5e535e;
         border-right: 20px solid #666666;
         border-bottom: 20px solid #666666;
         -webkit-transform: rotate(45deg);
         display: inline-block;
     }
 
     #logo .needle
     {
         width: 5px;
         height: 50px;
         background: blue;
         border-bottom-left-radius: 5px;
         border-bottom-right-radius: 5px;
         border-top-left-radius: 100%;
         border-top-right-radius: 100%;
         display: inline-block;
         left: 56px;
         position: absolute;
         top: 10px;
         font-size:x-large;
         color:white;
     }
 
     #logo .reading_insurance_service
     {
         -webkit-animation: move_insurance_service 5s forwards;
       transform: rotate(0deg);
       transform-origin: bottom; 
       animation-fill-mode: forwards;
     }
     @-webkit-keyframes move_insurance_service {
       0% {
         transform: rotate(-90deg);
         background: blue;
       }
       50% {
         transform: rotate(90deg);
         background: blue;
       }
       100% { 
         transform: rotate(-49deg);
         transform: rotate(calc(((45deg*100)/75)*(180/100) - 90deg));
         background: gold; 
       }
     }
 
     #logo .reading_logistics_service
     {
         -webkit-animation: move_logistics_service 5s forwards;
       transform: rotate(0deg);
       transform-origin: bottom; 
       animation-fill-mode: forwards;
     }
     @-webkit-keyframes move_logistics_service {
       0% {
         transform: rotate(-90deg);
         background: blue;
       }
       50% {
         transform: rotate(90deg);
         background: blue;
       }
       100% { 
         transform: rotate(-90deg);
         transform: rotate(calc(((0deg*100)/35)*(180/100) - 90deg));
         background: red; 
       }
     }
 
     #logo .reading_products
     {
         -webkit-animation: move_products 5s forwards;
       transform: rotate(0deg);
       transform-origin: bottom; 
       animation-fill-mode: forwards;
     }
     @-webkit-keyframes move_products {
       0% {
         transform: rotate(-90deg);
         background: blue;
       }
       50% {
         transform: rotate(90deg);
         background: blue;
       }
       100% { 
         transform: rotate(-45deg);
         transform: rotate(calc(((3deg*100)/35)*(180/100) - 90deg));
         background: gold; 
       }
     }
 .debuglinks {
    color: white;
 }
 .debuglinks:hover {
    background-color: lighblue;
 }
 .ox-links a {
     display: block;
     position: relative;
 }
 .ox-links a:hover {
 color: lighblue;
 text-decoration: none;
 font-weight: bold;
 }
 .ox-links a::after {
     transition: transform 400ms ease-in;
     content: "";
     width: 100%;
     bottom: 0;
     left: 0;
     position: absolute;
     height: 2px;
     background-color: #10c55f;
     transform: scale(0);
 }
 .ox-links a:hover::after {
     transform: scale(0.5);
     transform-origin: center;
 }
 
 
 :root {
 --status-color: Assigned;
 }
 .bg_funnel { 
    background-image:url(https://demo.oxzion.com/data/uploads/organization/1/userfiles/191_image.png);
    background-position-x:center; background-position-y:center; 
    background-size:100%; 
 } 
 .bg_funnel_1 { 
    background-image:url(https://demo.oxzion.com/data/uploads/organization/1/userfiles/193_image.png); 
    background-position-x:center; 
    background-position-y:center; background-size:50%; 
 } 
 .bg_funnel_2 { 
    background-image:url(https://demo.oxzion.com/data/uploads/organization/1/userfiles/194_image.png); 
    background-position-x:center; 
    background-position-y:center; 
    background-size:50%; 
 } 
 .bg_funnel_3 { 
    background-image:url(https://demo.oxzion.com/data/uploads/organization/1/userfiles/195_image.png); 
    background-position-x:center; 
    background-position-y:center; 
    background-size:50%; 
 } 
 .bg_funnel_4 { 
    background-image:url(https://demo.oxzion.com/data/uploads/organization/1/userfiles/196_image.png); 
    background-position-x:center; 
    background-position-y:center; 
    background-size:50%; 
 }
 
 #box-contain1 {
 padding: 30px;border:2px dashed teal;
 margin-bottom: 5px;
 }
 #box-contain2 {
 padding: 30px;
 border:5px double teal;
 }

 figure.oxzion-widget,
div.oxzion-widget
{
	text-align: center;
	outline: solid 1px #ccc;
	background: rgba(0,0,0,0.05);
	padding: 10px;
	margin: 10px 20px;
	display: inline-block;
}

/* figure > figcaption, */
figure.oxzion-widget > figcaption
{
	text-align: center;
	display: block; /* For IE8 */
}