@import "./variables.scss";

.dashboard-editor-form .form-group {
  margin-left: 5px;
  min-width: 140px;
}

.dashboard-back-btn {
  position: absolute;
  right: 98px;
}


.dashboard .flippy-back {
  height: 120vh !important;
  min-height: 70vh;
}

.dashboard .flippy-front {
  height: auto;
  min-height: 70vh;
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0);
}

.dashboard-filter-btn {
  position: absolute;
  right: 2px;
}

.dashboard-save-btn {
  position: absolute;
  right: 50px;
}

.dashboard .btn-primary {
  color: #fff;
  background-color: #265160;
  border-color: #265160;
}

.dashboard .btn-primary:hover {
  background-image: none !important;
  background-color: #265160c4 !important;
}


.dashboard .deactivateDash .react-switch-bg {
  background: rgb(39, 83, 98) !important;
}

.dashboard .react-switch-handle {
  background: #fff !important;

}

//dashboard editor filter
.create-filter-form {
  flex-direction: column;
  padding: 5px 5px 0 5px;
  background: white;
  display: flex;
  flex: 1;
}

.create-filter-form .btn {
  bottom: 0px;
  right: 0px;
  position: absolute;
}

.hide-dash-editor {
  display: none;
}

.dates-container {
  display: flex;
  gap: 1rem;
}

.react-datepicker-wrapper {
  padding-right: 0px !important;
}

.dashboard-filter-field {
  min-width: 15px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.dashboard-filter-field .form-checkbox {
  width: 20px;
  height: 20px;
}


.dates-container input,
.create-filter-form input {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 12vw;
}

.dates-container input {
  width: 140px;

}

#filtertype {
  max-width: 23vw;
}

div.filter-form-container {
  background: white;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: -3px 3px 18px #cccccc;
}

div.filter-form-container.show-filter-panel {
  background: white;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: -3px 3px 18px #cccccc;
  overflow-y: scroll;
  height: 70vh;
  max-height: 70vh;
  position: absolute;
  z-index: 10000;
  right: 0;
  top: 0;
  transition: 0.8s ease-in;
}

// .show-filter-panel {

// }

.disappear {
  display: none;
}

.blurOut {
  display: block;
  filter: blur(5px);
  pointer-events: none;
}

#filtereditor-form-container button.close,
.filter-form-container button.close {
  background-color: transparent;
  border-radius: none;
}

#filtereditor-form-container label,
.filter-form-container label {
  color: #275362;
}

#filtereditor-form-container {
  background-color: white;
  border-radius: 10px;
  margin-top: 60px;
}

.apply-filter-btn-wrapper {
  display: flex;
  margin: 10px auto;
  padding-bottom: 10px;
}

.apply-filter-btn {
  border-radius: 0px;
  margin-left: 0px;
  flex: 1;
}

.custom-react-select-container {
  height: auto;
  padding: 8px 12px;
  display: flex;
  box-shadow: 0px 0px 5px 0px grey;
}

.custom-react-select-container:hover {
  background-color: #DEEBFF !important;
  width: 100%;

}

.custom-react-select-container i {
  // float: right;
  // right: 0px;
  margin-left: auto;
}

.dash-manager-bar {
  // border: 0.5px solid#3d6776;
  // padding: 1em;
  padding-top: 12px;
  padding-bottom: 0px;
  position: relative;
  width: auto;
  float: right;
  right: 0;
  // background-image: linear-gradient(to right top, #3d6776, #4b6f7c, #587682, #657e88, #72868e);
  // min-height: 65px;
}

.dashboard-viewer-div {
  position: relative;
  margin-top: 0em !important;
  float: left;
  width: 100%;
}



.react-select-container {
  z-index: 100;
  width: 300px;
  float: left;
  right: 10px;

}

.dash-manager-buttons .btn {
  margin-right: 7px;
  border-color: $button-border-color !important;
}

.dash-manager-buttons {
  right: 0px;
  width: auto;
  float: right;
  position: relative;
}

.dash-manager-buttons .btn.btn-primary {
  color: $button-color !important;
  background-color: $button-background-color !important;
  border-radius: 42px;
  font-size: 13px !important;
  box-shadow: 0px 0px 4px 0px grey;

  padding: 7px 9px 7px 11px;
  margin-bottom: 4px;
}

.dash-manager-buttons .btn.btn-primary:hover {
  color: $button-color;
  border-color: $button-border-color-hover;
  background-color: $button-background-color-hover !important;
}

.dasboard-viewer-wrapper {
  display: flex;
  flex-direction: column;

  .dashboard-drilldown-info-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0px;
  }
}

.dasboard-viewer-content {
  background-color: #f3f3f3;
  border: none;
  border-radius: 0;
  box-shadow: none;
  min-width: 100%;
  margin-top: 1em;
  // margin-top: 4em;
}

.dashboard-rollup-button {
  color: white;
  cursor: pointer;
  right: 0%;
  position: absolute;
  top: 15%;
  background-color: rgb(39, 83, 98);
  overflow: hidden;
  padding: 0.5em 1em;
  border-radius: 0 0.25rem 0.25rem 0;
}

.dashboard-filter-description {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 100%;
  font-size: 16px !important;
}

.dashboard-preview-tab {
  background-image: linear-gradient(to right, #275362, #235764, #205b65, #1d5f65, #1d6365);
  height: 1.3em;
  color: #f2f2f2;
  top: 50%;
  width: 15em;
  text-align: center;
}

.dashboard-preview-tab span {
  position: relative;
  top: 0.1em;
}

.create-dash-btn {
  margin-left: 1em;
  margin-bottom: 10px;
}

.Window_Analytics .dashboard .GridCustomStyle {
  height: calc(100% - 91px) !important
}

.Window_Analytics .dashboard label {
  color: #265160 !important;
}

.dashboard-container {
  margin-top: 4em;
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: #656565 !important
}

.k-grid-header .k-grid-filter.k-state-active,
.k-grid-header .k-header-column-menu.k-state-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-state-active {
  color: #fff;
  background-color: #656565 !important
}

.k-pager-numbers .k-link.k-state-selected {
  color: #656565 !important;
  background-color: rgba(78, 73, 73, 0.2) !important
}

.k-pager-numbers .k-link {
  color: #656565 !important;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  color: #656565 !important;
  background-color: rgba(78, 73, 73, 0.2) !important
}

/* 
  With discussion with Harsha, Vinu and UV:
  Whenever there is a drilldown grid
  The solutions provided is we can mark the first cell of all rows blue and underline, 
  so they know that know its a link
*/
.drillDownStyle table tr:not(.k-grid-norecords) td:first-child {
  color: blue;
  text-decoration: underline;
}

.drillDownStyle .k-master-row:hover {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer !important;
}

.filter_remove_button {
  cursor: pointer;
  float: left;
  vertical-align: middle;
  margin-top: 25px;
  position: relative;
  margin-left: 10px;
}

.filter_remove_button_view,
.filter_remove_button_view.btn.btn-primary {
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 2px 7px;
}

.errorMsg {
  color: red !important;
}

.dashboard-filter-field {
  font-size: 13px;
}

.dashboardTextField {
  font-size: 13px !important;
  height: 38px !important;
}

.field-width-75 {
  width:75px !important;
}

.field-width-100 {
  width:100px !important;
}

.field-width-150 {
  width:150px !important;
}

.field-width-200 {
  width:200px !important;
}

.field-width-300 {
  width: 300px !important;
}

.select_notif {
  padding: 4px;
  color: red;
  font-size: 12px;
  margin-top: 0px;
  // background: rgba(255, 255, 0, 0.453);
  // float: left;
  // margin-bottom: 15px;
}

.toastHide {
  visibility: hidden;
}

.full-width {
  widows: 100%;
  overflow: hidden;
}

.filterFieldsfilter {
  //display: inline-flex !important;
  display: inline-block;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  // border-left: 2px solid #265160;
  // border-bottom: 2px solid #27536296;
  box-shadow: 4px 4px 10px #ebebeb, -4px -4px 10px #eaeaeab5;
  margin-bottom: 10px;
  padding: 0 5px;
}

.dashboard-filter-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;

  .filter-header-panel {
    display: flex;
    flex: 0;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 5px;

    .filter-header-text {
      font-size: 24px;
      color: #5d6d80;
      margin-bottom: 0;
    }
  }
}

.dashboard-filter-name {
  letter-spacing: 1px;
  flex-basis: 50%;
  font-size: 16px;
  color: #275362;
  padding: 5px;
  border-radius: 5px;
  margin-right: 1rem;
}

// View Mode - Filter Fields CSS
.filter-form-container {
  .filterFieldsfilter {
    .filterFieldsfilter-section-one {
      display: flex;
      align-items: center;
      flex-basis: 100%;
      gap: 10px;

      .dashboardTextField {
        flex-basis: 100%;
      }

      select.dashboardTextField.form-control {
        border: none;
      }
      #value.dashboardTextField {
        flex-basis: 100%;
      }
    }

    .filterFieldsfilter-section-two {
      flex: 1 0 100%;
      .dashboard-filter-field {
        display: flex;
        justify-content: center;
      }
      #value.dashboardTextField {
        flex-basis: 100%;
      }
    }
  }
}

// EDIT Mode - Filter Fields CSS
#filtereditor-form-container {
  .create-filter-form {
    display: flex;
    justify-content: flex-start;

    > .filterFields.form-row {
      margin: 10px 0px;
      padding: 5px;
      box-shadow: 4px 4px 10px #ebebeb, -4px -4px 10px #eaeaeab5;
      gap: 5px;
      align-items: center;

      .filterFieldsfilter-section-two {
        flex: 1;
        align-self: flex-end;
        > .dashboard-filter-field {
          flex: 1;
        }
        #value.dashboardTextField {
          flex-basis: 0;
        }
        .dates-container { 
          display: inline-block;
        }
      }

      .dash-manager-buttons {
        display: flex;
        align-items: flex-end;
        margin-left: auto;
        align-self: flex-end;
      }
    }
  }
}

input[type="checkbox"] {
  // transform: scale(1.5);
  height: 12px;
  width: 12px;
}
