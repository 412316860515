#printPDFFile{
    .details_block{
        background-color: lavender; 
        color: blue;
        padding: 5px 10px 10px 10px;
    }  
    input[type=checkbox]:not(old),
    input[type=radio]:not(old){
        width : 1.5em;
        height : 1.5em;
        cursor : pointer;
        vertical-align : bottom;
    }
    .bg-radio{
        margin-left: 10px !important;
    }
    .bg-check{
        margin-left: 50px !important;
    }
    .printWindow{
        z-index: 15000;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
    }
    .iframePortlet{
        display: flex;
        flex-grow: 1;
    }
    .k-window-titlebar {
        display: none !important;
    }
    
    .k-window.k-widget {
        top: 0 !important;
        left: 0 !important;
        height: calc(100% - 5px) !important;
        width: calc(100% - 5px) !important;
    }
    
    .format-label{
        font-size : 14px;
        margin : 0px 5px;
        cursor : pointer;
    }
    .hide{
        display : none;
    }
    
    #browser-no-support{
        text-align: center;
        font-size: 18px;
        padding: 20px 0px;
        color: red;
    }   
}
.print-data-window{
    display: flex;
    flex-direction: column;
    height: 100%;

    #print-controls{
        display: flex;
        gap: 11px;
        align-items: center;

        .format-label{
            margin: 0;
        }

        div{
            flex: 1;
            text-align: end;
        }
    }

    iframe{
        display: block;
        flex: 1;
        height: 100%;
    }
}