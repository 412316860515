.template-search-header{
    align-items: center;
    background: white;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
    width: 100%;
}

.template-selected-file{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid;
    border-radius: 3px;
}